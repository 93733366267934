import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  OnInit
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserValidators } from '../user.util';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { first } from 'rxjs';

@Component({
  selector: 'zlo-forgot-password',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./../containers/login-page.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output()
  submitted = new EventEmitter<string>();

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', UserValidators.requiredEmail)
  });

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(first())
      .subscribe((params: Params) => {
        if (params.email) {
          this.form.get('email').setValue(params.email);
          this.router.navigate([], {
            replaceUrl: true
          });
        }
      });
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.get('email').value.trim());
    }
  }
}
