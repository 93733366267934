import { ValidationError } from 'yup';
import { Step } from '.';
import { Department, UserAttrs, Language } from '..';

export type RecipientChannel =
  | 'email'
  | 'slack'
  | 'sms'
  | 'teams'
  | 'whatsapp'
  | 'workplace';

export class ZeloValidationError extends ValidationError {
  stepsErrorCount: number;
  hasInfoStepError = false;
}

export interface DistributorJob {
  id: string;
  jobType: string;
  organizationId: string;
  audienceId: string;
  startAt: string;
  startAtTimezone: string;
  finishBy: string;
  finishByTimezone: string;
  suspended: boolean;
  size: number;
  counter: number;
  createdAt: Date;
  metadata?: any;
}
export interface ZeloOrganization {
  id: string;
  name: string;
  logo: string;
  language?: string;
}

export type ZeloScheduleType =
  | 'all_optimal_time'
  | 'individual_optimal_time'
  | 'at_time'
  | 'follow_the_sun';

export type ZeloStatus = 'draft' | 'sent' | 'scheduled' | 'invalid';
export type ZeloMode = 'newsletter' | 'no_link' | 'zelo_content';

export interface ZeloSummary {
  id: string;
  title: string;
  updatedAt: string;
  parent: string;
  mode: string;
  dateSent: string;
  channelId: string;
  channel: string;
  isArchived: boolean;
  isDraft: boolean;
  isScheduled: boolean;
  isSent: boolean;
  isDeleted: boolean;
  isSelected?: boolean;
  categories?: Category[];
  distributorJobs?: DistributorJob[];
  scheduleType?: ZeloScheduleType;
  steps?: Step[];
  meta?: object;
  stats?: {
    responseCount: number;
    viewedCount: number;
    openedCount: number;
    respondedCount: number;
  };
}

export interface Zelo {
  id: string;
  title: string;
  description: string;
  isSent: boolean;
  status: ZeloStatus | null;
  audienceId: string | null;
  audience2?: AudienceV2;
  channelId: string | null;
  channel: string;
  parent: string | null;
  isValid: boolean;
  isArchived: boolean;
  isDraft: boolean;
  isScheduled: boolean;
  isDeleted: boolean;
  dateSent: string;
  scheduleType: ZeloScheduleType;
  categories?: Category[];
  channelMessages?: ZeloChannelMessage[];
  isSaving: boolean;
  steps: Step[];
  dateArchived: string;
  responseCount: number;
  openedCount: number;
  respondedCount: number;
  createdAt: string;
  updatedAt: string;
  organization: ZeloOrganization;
  recipientIds: string[];
  errors?: ZeloValidationError;
  mode: ZeloMode;
  distributorJobs?: DistributorJob[];
  senderId: string;
  users?: Employee[];
  notifyOnSignupEmails: [];
  signup: boolean;
  signupAttributes: UserAttrs[];
  organizationId: string;
  schedule?: {
    scheduleType: ZeloScheduleType | null;
    startAt: string;
    finishBy: string;
    startAtTimezone: string;
    finishByTimezone: string;
  };
  isSelected?: boolean;
  meta?: ZeloMeta;
}

interface ZeloMeta {
  enableLinkSharing?: boolean;
  notifyOnSignupViaLinkSharing?: string[];
  attributeNames?: string[];
  signupLanguage?: Language;
  department?: string;
  anonymous?: boolean;
  certificate?: ZeloCourseCertificate;
}

export interface ZeloCourseCertificate {
  enabled?: boolean;
  sendEmailOnCompletion?: boolean;
  description?: string;
  signature?: string;
  pdf?: string;
}

export interface ZeloResponse {
  id: string;
  zeloId: string;
  description: string;
  channelId?: string;
  channel?: string;
  steps: Step[];
  userId?: string;
  user?: Employee;
  dateOpened: string;
  dateSent: string;
  dateResponded: string;
  dateLastReminded: string;
  dateViewed: string;
  dateDelivered: string;
  isLastCheckPoint: boolean;
  lastVisitedStepIdx?: number;
  organization: ZeloOrganization;
  categories?: Category[];
  zelo?: Partial<Zelo>;
  emailChannelMessage?: ZeloChannelMessage;
  userDeletedAt?: string;
  remindersSent?: number;
}

export const AttributeGroupTypes = ['include', 'exclude'] as const;

export interface AttributeTerm {
  name: string;
  attributeName: string;
  selected?: boolean;
}

export interface AttributeGroup {
  id?: string;
  type: typeof AttributeGroupTypes[number];
  attributeTerms: AttributeTerm[];
  attribute: {
    name: string;
  };
}

export interface AudienceRuleV2 {
  op: 'and' | 'or';
  negate: boolean;
  terms: string[];
}

export interface AudienceFilterV2 {
  added?: Date;
  rules?: Record<string, AudienceRuleV2>;
  individuals?: Record<string, string>;
}

export interface AttrZelo {
  zeloId?: string;
  meta?: any;
  filters: AudienceFilterV2[] | 'everyone';
}

export interface AttrAudience {
  name: string;
  added?: Date;
  tags?: Record<string, Date>;
  i18n?: Record<string, string>;
  filters?: AudienceFilterV2[] | 'everyone';
}

export interface AttrAudienceKey {
  limit: number;
  tags: string[];
  name: string;
}

export type AudienceV2 = AttrZelo | AttrAudience | 'everyone';
export type Muster = Partial<Employee>[] | { count: number } | any;

export type MusterExplain = {
  filter?: string;
  rule?: string;
  stat?: string;
};

export interface MusterStation {
  count?: boolean;
  explain?: MusterExplain;
  responses?: boolean;
  page?: number;
  limit?: number;
  searchField?: string;
  begins?: string;
  sortField?: string;
  descending?: boolean;
  subfilter?: AudienceV2;
  audience?: AudienceV2;
  negate?: boolean;
  channel?: string;
  audienceId?: string;
  notSent?: boolean;
  notResponded?: boolean;
  zeloId?: string;
}

export interface OrganizationAttribute {
  name: string;
  attributeTerms: AttributeTerm[];
}

export interface AttributedChannelRecipient {
  id?: string;
  name: string;
  filter: string;
  channel: string;
}

export interface ZeloChannelSummary {
  channel: string;
  percentageOfSending: string;
  recipients: number;
  channelMessage: string;
}

export interface ZeloChannelMessage {
  zeloId: string;
  channelId?: string;
  channel: string;
  language: string;
  messageProperties: any;
}

export interface ResponseNavigationEvent {
  action: 'next' | 'prev';
  responseId: string;
  stepNumber: number;
  stepId: string;
}

// Const assertions from typescript is used hereunder
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
export const ResponseVideoEventActions = [
  'played_video',
  'paused_video',
  'skipped_video',
  'ended_video'
] as const;

export interface ResponseVideoEvent {
  action: typeof ResponseVideoEventActions[number];
  responseId: string;
  stepNumber: number;
  stepId: string;
}

export interface UserStatistics {
  quickestSentToCompleted: number;
  slowestSentToCompleted: number;
  avgSentToCompleted: number;
  receivedZelosCount: number;
  openedZelosPercent: number;
  completedZelosCount: number;
  responses: ZeloResponse[];
  organization: ZeloOrganization;
  user: {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
  };
}

export interface Folder {
  name: string;
  size: number;
}

export interface Category {
  id?: string;
  title: string;
}

export type LoginMethods = 'email' | 'sso';

export interface Employee {
  id: string;
  firstname?: string;
  lastname?: string;
  employeeTitle?: string;
  email?: string;
  phone?: string;
  workplaceId?: string;
  remoteId?: string;
  roles?: string[];
  organizationId?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  isSelected?: boolean;
  departments?: Pick<Department, 'id' | 'name'>[];
  attributes?: {
    attributeName: string;
    termName: string;
  }[];
  attributeTerms?: AttributeTerm[];
  attrs?: UserAttrs;
  settings?: EndUserSettings;
  loginMethods?: LoginMethods[];
}

export type NewEmployee = Omit<
  Employee,
  'id' | 'createdAt' | 'updatedAt' | 'verified' | 'attributeTerms'
>;

export interface IndividuallyAddedUser extends Partial<Employee> {
  isAddedAfterZeloIsSent?: boolean;
}

export function emptyEmployee(): Employee {
  return {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    createdAt: null,
    updatedAt: null,
    attributeTerms: [],
    attrs: []
  };
}

export interface EndUserSettings {
  userId?: string;
  id?: string;
  phone?: string;
  organizationId?: string;
  preferredChannelId?: string;
  preferredChannel?: string;
  preferredLanguage?: string;
  preferredDay?: string;
  preferredTime?: string;
  preferredTimezone?: string;
  latestTermsAccepted?: string;
  phoneConsent?: boolean;
  requirePhoneConsent?: boolean;
  privacyVersion?: string;
  privacyLink?: string;
}

export interface PreferredChannel {
  id: string;
  name: RecipientChannel;
}

export interface EmailMessageProperties {
  message: string;
  img?: string;
  messageJSON?: string;
  title?: string;
  origin?: string;
}

export interface ZeloBeeSaveRows {
  id?: string;
  language?: string;
  title?: string;
  name?: string;
  messageProperties?: any;
}

export type ZeloRowAction =
  | 'duplicate'
  | 'archive'
  | 'delete'
  | 'moveToFolder'
  | 'removeFolder'
  | 'contentPreview'
  | 'emailPreview'
  | 'moveZeloToFolder'
  | 'handleSettingsInitialized'
  | 'handleSettingsOpened';
